
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  computed,
  PropType,
} from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { modalShowListener } from "@/core/directive/function/common";
import _ from "lodash";
import { ApiBase, ApiGeneralInformation } from "@/core/api";
import { useRouter } from "vue-router";
import { NumberOrString } from "@/core/directive/type/common";
import axios, { CancelTokenSource } from "axios";
import { TaggingItem } from "@/core/directive/interface/common";
import { cityManagementEditable } from "@/core/directive/interface/generalInformation";
import { generalInformationStatus } from "@/core/directive/type/generalInformation";

export default defineComponent({
  name: "add-kyc-verification-modal",
  computed: {
    generalInformationStatus() {
      return generalInformationStatus;
    },
  },
  emits: ["update-list", "update-list-last-page", "reset-form", "modal-hide"],
  props: {
    itemId: {
      type: [String, Number] as PropType<NumberOrString>,
      default: "",
    },
  },
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const AddCityModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const {
      showServerErrorMsg,
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();
    const { t } = useI18n();
    const router = useRouter();
    const isChanged = ref(false);
    const initialData = ref(Object.assign({}, cityManagementEditable));
    const formData = ref(Object.assign({}, cityManagementEditable));
    const lastData = ref(Object.assign({}, cityManagementEditable));
    const options = ref({
      cancelSource: null as CancelTokenSource | null,
      countryLoading: false,
      countryOptions: [] as TaggingItem[],
    });
    const rules = ref({
      country_code: [
        {
          required: true,
          message: t("common.thisIsRequiredField"),
          trigger: "change",
        },
      ],
      local_name: [
        {
          required: true,
          message: t("common.thisIsRequiredField"),
          trigger: "change",
        },
      ],
    });

    watch(
      () => formData.value,
      () => {
        isChanged.value =
          JSON.stringify(formData.value) !== JSON.stringify(lastData.value);
      },
      {
        deep: true,
      }
    );

    const getShowInfo = async () => {
      if (props.itemId === 0) {
        resetForm();
        return;
      }
      loading.value = true;
      const cancelToken = axios.CancelToken;
      options.value.cancelSource = cancelToken.source();
      const { data } = await ApiGeneralInformation.getAddressEditable({
        id: props.itemId,
      });
      loading.value = false;
      if (data.code === 0) {
        formData.value = data.data;
        lastData.value = _.cloneDeep(formData.value);
      } else {
        showServerErrorMsg(data);
      }
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.countryOptions = data.data;
      }
    };

    const onCountryChange = async (countryCode) => {
      const result = options.value.countryOptions.find(
        (item) => item.value == countryCode
      );
      formData.value.country = result?.label as string;
    };

    const submit = (type: string) => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (props.itemId === 0) {
            // 新增
            const { data } = await ApiGeneralInformation.addAddress(
              formData.value
            );
            loading.value = false;
            if (data.code == 0) {
              showFormSubmitSuccessMsg(() => {
                updateList();
              });
            } else {
              showServerErrorMsg(data);
            }
          } else {
            // 编辑
            const { data } = await ApiGeneralInformation.updateAddress(
              formData.value
            );
            loading.value = false;
            if (data.code == 0) {
              showFormSubmitSuccessMsg(() => {
                updateList();
              });
            } else {
              showServerErrorMsg(data);
            }
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const reset = () => {
      getShowInfo();
    };

    const updateList = (goLastPage = false) => {
      if (!goLastPage) {
        emit("update-list");
      } else {
        emit("update-list-last-page");
      }
      modalHide();
    };

    const modalHide = () => {
      hideModal(AddCityModalRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value = initialData.value;
      emit("reset-form");
    };

    const isEditModal = computed(() => {
      return props.itemId !== 0;
    });

    onMounted(() => {
      getCountryData();
      modalShowListener(AddCityModalRef.value, () => {
        setTimeout(() => {
          getShowInfo();
        }, 0);
      });
      modalHideListener(AddCityModalRef.value, () => {
        resetForm();
        emit("modal-hide");
      });
    });

    return {
      props,
      router,
      loading,
      formData,
      rules,
      formRef,
      AddCityModalRef,
      options,
      submit,
      reset,
      isChanged,
      updateList,
      isEditModal,
      onCountryChange,
      modalHide,
    };
  },
});
