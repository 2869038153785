
import MBDatatablePlus from "@/components/mb-datatable/DataTablePlus.vue";
import AddModel from "./AddModel.vue";
import { computed, onActivated, onMounted, onUpdated, ref } from "vue";
import {
  FilterOption,
  SortCounter,
  SortOption,
  TableHeader,
  TaggingItem,
} from "@/core/directive/interface/common";
import { useI18n } from "vue-i18n";
import {
  commonExportFile,
  formatUtcDate,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { ApiGeneralInformation } from "@/core/api";
import _ from "lodash";
import { useComputedFn } from "@/core/directive/function/common";
import { CancelTokenSource } from "axios";
import { MenuComponent } from "@/assets/ts/components";
import { getGeneralInformationStatus } from "@/core/directive/function/generalInformation";
import Swal from "sweetalert2";
import mixin from "@/mixins";
import { generalInformationStatus } from "@/core/directive/type/generalInformation";

export default {
  name: "kyc-verification-history-logs",
  components: {
    MBDatatablePlus,
    AddModel,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const { showServerErrorMsg, showFormSubmitSuccessMsg } = mixin();
    const tableData = ref<Array<any>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const sortOrder = ref("asc");
    const sortLabel = ref("");
    const tableLoading = ref(false);
    const search = ref<string>("");
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const disabledExportAll = ref(false);
    const modalItemId = ref<number>(0);

    const tableHeader = computed(() => {
      let arr: TableHeader[] = [
        {
          name: t("generalInformation.cityManagement.field.number"),
          key: "id",
        },
        {
          name: t("generalInformation.cityManagement.field.countryRegion"),
          key: "country",
        },
        {
          name: t("generalInformation.cityManagement.field.cityTown"),
          key: "local_name",
        },
        {
          name: "",
          key: "name_en",
        },
        {
          name: "",
          key: "name_de",
        },
        {
          name: "",
          key: "name_zh",
        },
        {
          name: t("common.status"),
          key: "status",
          align: "right",
        },
        {
          name: t("common.actions"),
          key: "actions",
          sortable: false,
        },
      ];
      return arr;
    });

    const options = ref({
      defaultSort: [...sortOptions.value] as SortOption[],
      filter_group: [] as FilterOption[],
      source: (generalInformationStatus.Activated +
        "," +
        generalInformationStatus.Deactivated) as any,
      cancelSource: null as CancelTokenSource | null,
      sourceLoading: false,
      sourceOptions: [
        {
          label: t("common.all"),
          value:
            generalInformationStatus.Activated +
            "," +
            generalInformationStatus.Deactivated,
        },
        {
          label: t("common.activated"),
          value: generalInformationStatus.Activated,
        },
        {
          label: t("common.deactivated"),
          value: generalInformationStatus.Deactivated,
        },
      ] as TaggingItem[],
    });

    const setOtherFilter = computed(() => {
      let arr: FilterOption[] = [];
      if (typeof options.value.source === "string") {
        arr.push({
          field: "status",
          value: options.value.source.split(","),
          condition: "in",
        });
      } else {
        arr.push({
          field: "status",
          value: options.value.source,
          condition: "eq",
        });
      }
      return arr;
    });

    const setCommonFilter = computed(() => {
      return {
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        sort_orders: sortOptions.value,
        filter_group: [...filterOptions.value, ...setOtherFilter.value],
      };
    });

    /**
     * @description: 请求数据
     */
    const getList = async () => {
      tableLoading.value = true;

      const { data } = await ApiGeneralInformation.getAddressList(
        setCommonFilter.value
      );
      tableLoading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        total.value = data.data.total;
      }
    };

    /**
     * @description: 渲染：状态
     */
    const getStatus = useComputedFn(getGeneralInformationStatus);

    /**
     * @description: 获取列表
     */
    const updateList = () => {
      // currentPage.value = 1;
      getList();
    };
    const updateListLastPage = () => {
      currentPage.value = Math.ceil(total.value / pageSize.value);
      getList();
    };

    /**
     * @description: 重置过滤器（只修改参数）
     */
    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    /**
     * @description: 重置排序（拉取数据）
     */
    const handleSortReset = () => {
      resetColumnSort();
      updateList();
    };

    /**
     * @description: 重置排序（只修改参数）
     */
    const resetColumnSort = () => {
      sortOptions.value.splice(
        0,
        sortOptions.value.length,
        ...options.value.defaultSort
      );
      sortOrder.value = "desc";
      sortLabel.value = "";
    };

    /**
     * @description: 切换页码时
     */
    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getList();
    };

    /**
     * @description: 切换记录条数时
     */
    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      updateList();
    };

    const sortCounterFlag = [] as SortCounter[];

    /**
     * @description: 排序独立计数
     */
    const sortCounter = (field: string) => {
      if (sortCounterFlag.some((item) => item.field === field)) {
        sortCounterFlag.forEach((item) => {
          if (item.field === field) {
            item.counter = item.counter + 1;
          }
        });
      } else {
        sortCounterFlag.splice(0, sortCounterFlag.length, {
          field: field,
          counter: 1,
        });
      }
    };

    /**
     * @description: 排序3次判断
     */
    const onSortCounterThree = (val) => {
      let resetFlag = false;
      sortCounterFlag.forEach((item) => {
        if (item.field === val.columnName) {
          if (item.counter === 3) {
            sortCounterFlag.splice(0, sortCounterFlag.length);
            resetFlag = true;
          }
        }
      });
      return resetFlag;
    };

    /**
     * @description: 切换状态筛选器时
     */
    const handleSourceSelect = () => {
      resetFilter();
      resetColumnSort();
      updateList();
    };

    /**
     * @description: 排序时
     */
    const onColumnSort = (val: any) => {
      sortCounter(val.columnName);
      const resetFlag = onSortCounterThree(val);
      if (resetFlag) {
        handleSortReset();
      } else {
        let thisDirection = "";
        sortCounterFlag.forEach((item) => {
          if (item.field === val.columnName) {
            if (item.counter === 1) {
              thisDirection = "asc"; //第一次正序
            }
            if (item.counter === 2) {
              thisDirection = "desc"; //第二次倒序
            }
          }
        });
        sortOrder.value = thisDirection;
        const newSortRule = [] as SortOption[];
        if (val.columnName !== "id") {
          newSortRule.push(
            { field: val.columnName, direction: thisDirection },
            { field: "id", direction: "asc" }
          );
        } else {
          newSortRule.push({
            field: val.columnName,
            direction: thisDirection,
          });
        }
        sortOptions.value.splice(0, sortOptions.value.length, ...newSortRule);
        sortLabel.value = val.columnName;
        updateList();
      }
    };

    /**
     * @description: 搜索时
     */
    const debounceSearch = _.debounce(updateList, 1000);
    const searchItems = () => {
      debounceSearch();
    };
    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    /**
     * 增删改相关
     *
     *
     */
    const addItem = () => {
      modalItemId.value = 0;
    };

    const editItem = (item: any) => {
      modalItemId.value = item.id;
    };

    const deleteItem = async (item: any) => {
      Swal.fire({
        text: t("generalInformation.cityManagement.tips.deleteTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.noCancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await ApiGeneralInformation.deleteAddress({
            id: item.id,
          });
          if (data.code === 0) {
            showFormSubmitSuccessMsg(() => {
              updateList();
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    /**
     * 文件导出相关
     *
     *
     */
    const filterExportType = (type, flag = true) => {
      let params = {};
      if (type === "all") {
        if (flag) {
          disabledExportAll.value = true;
          params = {
            ...setCommonFilter.value,
          };
        } else {
          disabledExportAll.value = false;
        }
      }
      if (flag) {
        return params;
      }
    };

    /**
     * @description: 文件导出时
     */
    const filesExport = async (type = "all") => {
      let idParams = filterExportType("all");
      const data = await ApiGeneralInformation.exportAddressList({
        ...idParams,
      });
      filterExportType(type, false);
      commonExportFile(data);
    };

    const init = () => {
      setModuleBCN(t, route);
      updateList();
    };

    onMounted(() => {
      options.value.defaultSort.splice(
        0,
        options.value.defaultSort.length,
        ...sortOptions.value
      );
      options.value.filter_group = [...setCommonFilter.value.filter_group];
      init();
      MenuComponent.reinitialization();
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      formatUtcDate,
      tableData,
      getStatus,
      currentPage,
      total,
      pageSize,
      search,
      tableHeader,
      sortOrder,
      sortLabel,
      options,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      tableLoading,
      onColumnSort,
      handleSortReset,
      resetFilter,
      resetColumnSort,
      setCommonFilter,
      updateList,
      updateListLastPage,
      handleSourceSelect,
      disabledExportAll,
      filesExport,
      addItem,
      editItem,
      deleteItem,
      modalItemId,
    };
  },
};
