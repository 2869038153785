import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32c8fd7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header align-items-center py-5 gap-2 gap-md-5" }
const _hoisted_3 = { class: "card-title flex-row-fluid gap-5" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  class: "card-toolbar flex-row-fluid justify-content-end gap-5",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_8 = { class: "source-select-box rounded overflow-hidden" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 0,
  class: "spinner-border spinner-border-sm align-middle me-2"
}
const _hoisted_11 = {
  key: 1,
  class: "svg-icon svg-icon-3"
}
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "card-body pt-0" }
const _hoisted_14 = { class: "text-nowrap" }
const _hoisted_15 = { class: "text-gray-400 fs-7" }
const _hoisted_16 = { class: "text-nowrap" }
const _hoisted_17 = { class: "text-gray-400 fs-7" }
const _hoisted_18 = { class: "text-nowrap" }
const _hoisted_19 = { class: "text-gray-400 fs-7" }
const _hoisted_20 = { class: "text-nowrap" }
const _hoisted_21 = { class: "text-gray-400 fs-7" }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_MBDatatablePlus = _resolveComponent("MBDatatablePlus")!
  const _component_AddModel = _resolveComponent("AddModel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => ($setup.searchItems())),
              class: "form-control form-control-solid w-250px ps-14",
              placeholder: $setup.t('common.search')
            }, null, 40, _hoisted_6), [
              [_vModelText, $setup.search]
            ]),
            _withDirectives(_createElementVNode("span", {
              class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle cursor-pointer",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.removeSearch && $setup.removeSearch(...args)))
            }, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ], 512), [
              [_vShow, $setup.search != '']
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_select, {
              modelValue: $setup.options.source,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.options.source) = $event)),
              teleported: false,
              loading: $setup.options.sourceLoading,
              disabled: $setup.tableLoading,
              onChange: $setup.handleSourceSelect,
              placement: "bottom-start",
              "fit-input-width": true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options.sourceOptions, (item, key) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: key,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "loading", "disabled", "onChange"])
          ])
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-light-primary",
          onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.filesExport('all'))),
          disabled: $setup.disabledExportAll || $setup.tableLoading
        }, [
          ($setup.disabledExportAll)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10))
            : (_openBlock(), _createElementBlock("span", _hoisted_11, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr078.svg" })
              ])),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("common.export")), 1)
        ], 8, _hoisted_9),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-primary",
          "data-bs-toggle": "modal",
          "data-bs-target": "#modal_add_city",
          onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.addItem())),
          disabled: $setup.disabledExportAll || $setup.tableLoading
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("common.add")), 1)
        ], 8, _hoisted_12)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_MBDatatablePlus, {
          loading: $setup.tableLoading,
          "table-data": $setup.tableData,
          "table-header": $setup.tableHeader,
          order: $setup.sortOrder,
          "diy-order": true,
          "sort-label": $setup.sortLabel,
          "current-page": $setup.currentPage,
          total: $setup.total,
          "rows-per-page": $setup.pageSize,
          showExpandBtn: false,
          onCurrentChange: $setup.onCurrentPageChange,
          onItemsPerPageChange: $setup.onRowsPerPageChange,
          onDiySortClick: $setup.onColumnSort
        }, {
          "cell-id": _withCtx(({ row: item }) => [
            _createElementVNode("span", null, _toDisplayString(item.id || "--"), 1)
          ]),
          "cell-country": _withCtx(({ row: item }) => [
            _createElementVNode("span", null, _toDisplayString(item.country || "--") + "-" + _toDisplayString(item.country_code || "--"), 1)
          ]),
          "cell-local_name": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_14, _toDisplayString(item.local_name || "--"), 1),
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("generalInformation.cityManagement.field.localName")), 1)
          ]),
          "cell-name_en": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_16, _toDisplayString(item.name_en || "--"), 1),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("generalInformation.cityManagement.field.nameEN")), 1)
          ]),
          "cell-name_de": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_18, _toDisplayString(item.name_de || "--"), 1),
            _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("generalInformation.cityManagement.field.nameDE")), 1)
          ]),
          "cell-name_zh": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_20, _toDisplayString(item.name_zh || "--"), 1),
            _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("generalInformation.cityManagement.field.nameZH")), 1)
          ]),
          "cell-status": _withCtx(({ row: item }) => [
            (item.status)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass([$setup.getStatus(item.status, $setup.t).value.color, "badge"])
                }, _toDisplayString($setup.getStatus(item.status, $setup.t).value.title), 3))
              : (_openBlock(), _createElementBlock("span", _hoisted_22, "--"))
          ]),
          "cell-actions": _withCtx(({ row: item }) => [
            _createElementVNode("button", {
              class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
              "data-bs-toggle": "modal",
              "data-bs-target": "#modal_add_city",
              onClick: ($event: any) => ($setup.editItem(item))
            }, [
              _createElementVNode("span", _hoisted_24, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
              ])
            ], 8, _hoisted_23)
          ]),
          _: 1
        }, 8, ["loading", "table-data", "table-header", "order", "sort-label", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onDiySortClick"])
      ])
    ]),
    _createVNode(_component_AddModel, {
      "item-id": $setup.modalItemId,
      onUpdateList: $setup.updateList,
      onUpdateListLastPage: $setup.updateListLastPage
    }, null, 8, ["item-id", "onUpdateList", "onUpdateListLastPage"])
  ], 64))
}