/*
 * @Author: Nick juntong.wu@travel-easy.com
 * @Date: 2024-07-10 17:32:11
 * @LastEditors: Nick juntong.wu@travel-easy.com
 * @LastEditTime: 2024-07-10 17:32:39
 * @FilePath: \base-frontend\src\core\directive\interface\generalInformation.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { NumberOrString } from "../type/common";

export interface cityManagementEditable {
  country: string;
  country_code: string;
  state: string;
  local_name: string;
  name_en: string;
  name_de: string;
  name_zh: string;
  status: NumberOrString;
}
export const cityManagementEditable: cityManagementEditable = {
  country: "",
  country_code: "",
  state: "",
  local_name: "",
  name_en: "",
  name_de: "",
  name_zh: "",
  status: 10,
};
